import React from 'react';
import { Link } from 'gatsby';

import Logo from '../../images/assets/logo-white.svg';
import { useGraphQL } from '../../hooks/useGraphQL';
import phoneSVG from '../../images/assets/phone.svg';
import messageSVG from '../../images/assets/message.svg';
import locationSVG from '../../images/assets/location.svg';
import facebookSVG from '../../images/assets/facebook.svg';
import instagramSVG from '../../images/assets/instagram.svg';

const Footer = () => {
  const data = useGraphQL();
  return (
    <footer className="p-4 leading-none text-white bg-slategreen sm:px-12 lg:px-24">
      <div className="flex flex-wrap items-center justify-center w-full pb-4 mx-auto mt-6 border-b-0 border-white md:border-b md:justify-start max-w-1920px">
        <Link to="/" className="w-1/2 mb-4 md:w-1/3">
          <img
            className="w-full md:pr-4"
            style={{ maxWidth: `256px` }}
            src={Logo}
            alt="Morr logo."
          />
        </Link>
        <div className="flex flex-col w-full px-6 mt-8 text-left md:px-0 md:mt-0 lg:flex-row md:w-2/3">
          <div className="pt-8 border-t-2 border-white md:border-t-0 lg:w-1/2 lg:pt-0">
            <h2 className="mb-4 text-3xl font-bold">Contact</h2>
            <p className="mb-4">
              <img src={phoneSVG} alt="phone" className="inline-block" />
              <a className="inline-block pl-3" href="tel:0265839618">
                (02) 6583 9618
              </a>
            </p>
            <p className="mb-4">
              <img src={messageSVG} alt="message" className="inline-block" />
              <a
                className="inline-block pl-2"
                href={`mailto:${data.site.siteMetadata.email}`}
              >
                &nbsp;{data.site.siteMetadata.email}
              </a>
            </p>
            <p className="mb-4">
              <img src={locationSVG} alt="location" className="inline-block" />
              <a
                className="inline-block pl-2"
                href={data.site.siteMetadata.googleMaps}
              >
                &nbsp;{data.site.siteMetadata.address}
              </a>
            </p>
            {/* <p className="flex items-center justify-center sm:justify-end">
            Connect with us
            <FaInstagram className="inline-block ml-2 text-xl" />{' '}
            <FaFacebookF className="inline-block ml-1 text-xl" />
          </p> */}
          </div>
          <div className="w-full pt-8 mt-6 border-t-2 border-b-2 border-white md:border-t-0 md:border-b-0 lg:px-4 lg:w-1/2 md:mt-0 md:pt-0">
            <h2 className="mb-4 text-3xl font-bold">Navigation</h2>
            <nav className="grid max-w-xs grid-cols-2 grid-rows-2 mt-6 mb-4 gap-y-4">
              <Link to="/projects">Projects</Link>
              <Link to="/about">About</Link>

              <Link to="/services">Services</Link>
              <Link to="/contact">Contact</Link>
            </nav>
            <h2 className="inline-block mt-6 mb-4 text-xl font-bold">
              Follow us:
            </h2>
            <img
              src={facebookSVG}
              alt="facebook"
              className="inline-block ml-4 -mt-2"
            />
            <img
              src={instagramSVG}
              alt="instagram"
              className="inline-block ml-2 -mt-2"
            />
          </div>
        </div>
      </div>
      <p className="flex flex-wrap items-center justify-center w-full p-4 mt-4 mb-0 text-center text-md">
        <span className="w-full flex-no-shrink sm:w-auto">
          © {new Date().getFullYear()} {data.site.siteMetadata.title}
        </span>
        <span className="hidden w-full mx-2 sm:block sm:w-auto">|</span>
        <span className="w-full flex-no-shrink sm:w-auto">
          <a
            className="inline-flex px-1 font-semibold"
            href="https://pd.design"
            target="_blank"
            rel="noopener noreferrer"
          >
            Designed and developed by PD
          </a>
        </span>
      </p>
    </footer>
  );
};

export default Footer;
