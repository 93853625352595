import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Logo from '../../images/assets/logo-vertical-lockup-white.svg';
import { useGraphQL } from '../../hooks/useGraphQL';
import phoneSVG from '../../images/assets/phone.svg';

const Header = () => {
  const data = useGraphQL();
  const [isOpen, setOpen] = useState(false);
  return (
    <header className="relative sticky top-0 z-50 items-center w-full py-1 leading-none text-white bg-green-500 shadow md:flex">
      <div className="flex flex-wrap items-center w-full mx-auto max-w-1920px">
        <div className="flex justify-between w-full md:w-auto">
          <Link to="/" className="flex items-center px-4 sm:pl-12 lg:pl-56">
            <img className="h-12 py-2" src={Logo} alt="" />
          </Link>
          <button
            className="flex items-center justify-center px-2 py-1 m-4 md:hidden sm:m-2 sm:px-12"
            onClick={() => setOpen(!isOpen)}
            type="button"
          >
            <svg
              className="w-4 h-4 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <nav
          id="nav"
          className={`relative w-full md:flex md:flex-1 md:items-center md:justify-end md:w-auto ${
            isOpen ? 'block' : 'hidden'
          }`}
        >
          <NavLink link="/about" title="About" />
          {/* <span className="hidden md:inline-block">|</span> */}
          <NavLink link="/projects" title="Projects" />
          {/* <span className="hidden md:inline-block">|</span> */}
          <NavLink link="/services" title="Services" />
          {/* <span className="hidden md:inline-block">|</span> */}
          <NavLink link="/contact" title="Contact" />
          <a
            className="flex ml-6 border-t border-green-400 text-md md:border-none"
            href={`tel:${data.site.siteMetadata.phone}`}
          >
            <img src={phoneSVG} alt="phone call" className="" />
            <span
              className="inline-block p-2 mt-2 mb-1 ml-4 mr-4 font-bold text-white uppercase md:mb-0 sm:ml-12 md:ml-0 sm:mr-12 lg:mr-24 md:mt-0"
              style={{ whiteSpace: `nowrap` }}
            >
              (02) 6583 9618
            </span>
          </a>
        </nav>
      </div>
    </header>
  );
};

const NavLink = ({ link, title }) => (
  <Link
    to={link}
    className="block px-4 py-3 text-sm tracking-wide border-t border-green-400 hover:bg-green-400 md:border-none sm:px-12 md:px-3 md:py-2"
  >
    {title}
  </Link>
);

NavLink.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
};

export default Header;
