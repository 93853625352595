import React from 'react';
import PropTypes from 'prop-types';

import Header from '../Header';
import Footer from '../Footer';

import '../../css/tailwind.css';
import '../../css/global.css';

const Layout = ({ children }) => (
  <div className="flex flex-col w-full min-h-screen font-sans antialiased leading-relaxed text-gray-700 body">
    <Header />
    <main className="flex flex-col flex-1 w-full mx-auto bg-white">
      {children}
    </main>
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
