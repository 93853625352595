const defaultTheme = require('tailwindcss/defaultTheme');
const aspectRatio = require('tailwindcss-aspect-ratio');
const typography = require('@tailwindcss/typography');

module.exports = {
  purge: ['./src/**/*.js'],
  future: {
    removeDeprecatedGapUtilities: true,
    purgeLayersByDefault: true,
  },
  theme: {
    aspectRatio: {
      none: 0,
      square: [1, 1],
      '16/9': [16, 9],
      '4/3': [4, 3],
      '3/4': [3, 4],
    },
    typography: (theme) => ({
      default: {
        css: {
          a: {
            color: theme('colors.green[500]'),
          },
          'ol > li:before': {
            color: theme('colors.green[500]'),
          },
          'ul > li:before': {
            backgroundColor: theme('colors.green[500]'),
          },
        },
      },
    }),
    extend: {
      colors: {
        'transparent-black': 'hsla(0, 0%, 0%, .3333)',
        green: {
          100: '#f0fff0',
          200: '#c6f6c6',
          300: '#99e59c',
          400: '#66ce6d',
          500: '#3eb549',
          600: '#269d38',
          700: '#1f8131',
          800: '#206830',
          900: '#21522d',
        },
        slategreen: '#24404e',
        othergray: '#F9F9FA',
      },
      fontFamily: {
        sans: ['Metropolis', ...defaultTheme.fontFamily.sans],
      },
      maxWidth: {
        '1920px': '1920px',
      },
    },
  },
  // Default values here: https://tailwindcss.com/docs/pseudo-class-variants#default-variants-reference
  variants: {
    opacity: ['responsive', 'hover', 'focus', 'group-hover'],
    textColor: ['responsive', 'hover', 'focus', 'group-hover'],
    display: ['responsive', 'group-hover, responsive'],
  },
  plugins: [
    // https://github.com/webdna/tailwindcss-aspect-ratio
    aspectRatio,
    // https://github.com/tailwindlabs/tailwindcss-typography
    typography,
  ],
};
