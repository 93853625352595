import { graphql, useStaticQuery } from 'gatsby';

export function useGraphQL() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          address
          email
          googleMaps
          phone
          phoneFormatted
          title
        }
      }
      heroImage: file(relativePath: { eq: "hero2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aboutImage: file(relativePath: { eq: "about-section.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aboutHero: file(relativePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      team: file(relativePath: { regex: "/about-page.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      contactHero: file(relativePath: { regex: "/contact.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      projectsHero: file(relativePath: { regex: "/projects.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      servicesHero: file(relativePath: { regex: "/services.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      subscribeSectionImage: file(
        relativePath: { eq: "subscribe-section.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 960, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allSanityProject(sort: { order: DESC, fields: _updatedAt }) {
        nodes {
          _id
          slug {
            current
          }
          _updatedAt
          title
          _rawDescription(resolveReferences: { maxDepth: 50 })
          projectType {
            title
          }
          services {
            title
          }
          value
          location
          designer {
            name
          }
          ongoing
          completionDate(formatString: "MMMM YYYY")
          mainImage {
            asset {
              fluid(maxWidth: 750) {
                ...GatsbySanityImageFluid
              }
            }
          }
          sliderImages {
            asset {
              fluid(maxWidth: 750) {
                ...GatsbySanityImageFluid
              }
            }
          }
          awardBadge {
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
          awardTestimonials {
            _key
            comment
            images {
              asset {
                _id
                fixed(width: 150) {
                  ...GatsbySanityImageFixed
                }
              }
            }
          }
          # multiResidential: file(
          #   sourceInstanceName: { eq: "hero-images" }
          #   relativePath: { eq: "foreshore-ave.jpg" }
          # ) {
          #   childImageSharp {
          #     fluid(maxWidth: 960) {
          #       ...GatsbyImageSharpFluid_withWebp
          #     }
          #   }
          # }
          # customHomes: file(
          #   sourceInstanceName: { eq: "hero-images" }
          #   relativePath: { eq: "matthew-flinders.jpg" }
          # ) {
          #   childImageSharp {
          #     fluid(maxWidth: 960) {
          #       ...GatsbyImageSharpFluid_withWebp
          #     }
          #   }
          # }
          # commercial: file(
          #   sourceInstanceName: { eq: "hero-images" }
          #   relativePath: { eq: "discovery-early-learning.jpg" }
          # ) {
          #   childImageSharp {
          #     fluid(maxWidth: 960) {
          #       ...GatsbyImageSharpFluid_withWebp
          #     }
          #   }
          # }
          # designConstruct: file(
          #   sourceInstanceName: { eq: "hero-images" }
          #   relativePath: { eq: "ascot-villas.jpg" }
          # ) {
          #   childImageSharp {
          #     fluid(maxWidth: 960) {
          #       ...GatsbyImageSharpFluid_withWebp
          #     }
          #   }
          # }
        }
      }
    }
  `);
  return data;
}
