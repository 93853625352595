import React from 'react';
import Image from 'gatsby-image';
import { FaAngleDoubleDown } from 'react-icons/fa';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { useGraphQL } from '../hooks';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Location from '../components/Location';

const AboutPage = () => {
  const data = useGraphQL();
  return (
    <Layout>
      <SEO title="About" />
      <div className="relative">
        <Image
          style={{ height: `50vh`, minHeight: `22rem` }}
          fluid={data.aboutHero.childImageSharp.fluid}
          alt="Banner for Morr Homes and Projects."
          loading="eager"
        />
        <div className="absolute inset-0 flex items-end justify-center max-w-3xl p-4 mx-auto">
          <AnchorLink
            className="px-2 py-1 leading-none bg-white"
            href="#about"
            offset="56"
          >
            <FaAngleDoubleDown className="text-green-500" />
          </AnchorLink>
        </div>
      </div>
      <section
        id="about"
        className="flex flex-col p-4 my-8 md:mb-16 sm:px-12 lg:px-24"
      >
        <div className="flex flex-wrap w-full max-w-5xl mx-auto mb-8">
          <h1 className="w-full mb-4 text-4xl font-bold leading-none text-green-500 uppercase">
            About
          </h1>
          <div className="w-full max-w-lg pl-4 mb-4 text-gray-600 border-l-4 border-green-500 md:w-1/2">
            <h2 className="text-2xl font-bold">
              Regional Building Specialists
            </h2>
            <p className="mb-4">
              As a regional building company, Morr Homes and Projects understand
              the local environment, climate and community.
            </p>
            <p className="mb-4">Having this knowledge provides you with:</p>
            <ul className="bullets">
              <li>
                The best design and construction options for a well-rounded
                product.
              </li>
              <li>
                Maximum design appeal and living suitability to increase
                property value.
              </li>
              <li>
                A reliable team of quality focussed professional trades and
                suppliers.
              </li>
              <li>
                Established working relationships for efficient development
                approvals.
              </li>
            </ul>
            <p className="mb-4">
              Knowing the right people and processes cuts through the red tape
              (engineers, architects, council, professional consultants.)
            </p>
            <p className="mb-4">
              Understanding the <em>who</em>, <em>what</em> and <em>where</em>{' '}
              means that your quality project finishes on time and within your
              budget — an essential element in reducing the risk of costly
              blowouts.
            </p>
          </div>
          <div className="w-full max-w-lg pl-4 mb-4 text-gray-600 border-l-4 border-transparent md:w-1/2">
            <h2 className="mb-4 text-2xl font-bold leading-none">
              Providing a Positive Building Experience
            </h2>
            <p className="mb-4">
              Using proven practices, our project management is system based.
              With a key focus to deliver a quality outcome within an efficient
              process. From concept to creation, our tailored projects are
              unique. Investing the time in developing a comprehensive scope of
              works. And locking in an approved client brief before turning any
              soil. We're all about streamlining the process for a positive
              building experience.
            </p>
            <Image fluid={data.team.childImageSharp.fluid} />
          </div>
        </div>
        <div className="relative w-full px-4 py-16 text-white bg-green-500 sm:px-12 lg:px-24">
          <span className="flex justify-end triangle" />
          <div className="relative max-w-xl mx-auto">
            <svg
              className="absolute top-0 left-0 w-24 -mt-12 fill-current sm:-ml-12"
              style={{ color: `hsla(360, 100%, 100%, .33)` }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z" />
              </g>
            </svg>
            <p className="text-xl text-center">
              Understanding the who, what and where means that your quality
              project finishes on time and within your budget — an essential
              element in reducing the risk of costly blowouts.
            </p>
          </div>
        </div>
      </section>
      <Location />
    </Layout>
  );
};

export default AboutPage;
